<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <HelpButton />
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig"
import { provideToast } from "vue-toastification/composition"
import { watch } from "@vue/composition-api"
import { mapActions, mapMutations } from "vuex"
import useAppConfig from "@core/app-config/useAppConfig"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { useWindowSize, useCssVar } from "@vueuse/core"

import HelpButton from "@/components/HelpButton.vue"

import store from "@/store"

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue")
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue")
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue")

export default {
  components: {
    ToastificationContent,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    HelpButton,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full"
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr")
  },
  created() {
    if (JSON.parse(localStorage.getItem("userData"))) {
      this.setCurrentUser(JSON.parse(localStorage.getItem("userData")))
    }
    this.fetchLatestVersion().then((res) => {
      const latestVersion = res["latest_version"]
      const storedVersion = localStorage.getItem("latestVersion")
      if (latestVersion !== storedVersion) {
        localStorage.setItem("latestVersion", latestVersion)
        window.location.reload()
      }
    })
  },
  mounted() {
    this.pushNotifications().then((response) => {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "producto actualizado",
            text: response,
            icon: "EditIcon",
            variant: "success",
          },
        },
        {
          position: "bottom-left",
        }
      )
    })
    this.setOfflineMode(JSON.parse(localStorage.getItem("offlineMode")))
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout")

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    })

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val)
    })

    return {
      skinClasses,
    }
  },
  methods: {
    ...mapActions("notifications", ["pushNotifications"]),
    ...mapActions("appConfig", ["fetchLatestVersion"]),
    ...mapMutations("pos", ["setOfflineMode"]),
    ...mapMutations("auth", ["setCurrentUser"]),
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";

@media print {
  /* All your print styles go here */
  * {
    color: black !important ;
  }
}
</style>
