/* eslint-disable */

import { initializeApp } from '@/store/notifications/dependencies/firebase-app'
import { getAnalytics } from '@/store/notifications/dependencies/firebase-analytics'
import {
  getMessaging,
  getToken,
  onMessage,
} from '@/store/notifications/dependencies/firebase-messaging'
import axios from '@axios'

const firebaseConfig = {
  apiKey: 'AIzaSyBSEqUoNOiPhxrtAmHu2H7yAbDaELvAacA',
  authDomain: 'walleat-development.firebaseapp.com',
  projectId: 'walleat-development',
  storageBucket: 'walleat-development.appspot.com',
  messagingSenderId: '185057909460',
  appId: '1:185057909460:web:328496f5acdf2d8c38f941',
  measurementId: 'G-D6ZCRYPQ1V',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const messaging = getMessaging(app)

const actions = {
  pushNotifications(context) {
    return new Promise(resolve => {
      getToken(messaging, {
        vapidKey:
          'BMeCXL4O6g1d-C2A7NDlr2QRJ_HMg1n6UgLLnkGUEiL5niZ2pVmkRJ5q3Riv3LTXBbpmREQd-GhW5PbPCNScgas',
      })
        .then((currenToken) => {
          if (currenToken) {
            sessionStorage.setItem('notifications_token', currenToken)
            context.dispatch('updateFirebaseToken')
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            )
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })

      onMessage(getMessaging(), (payload) => {
        const product = JSON.parse(payload.data['gcm.notification.store_product'])

        let db

        const DBOpenRequest = window.indexedDB.open(`store-${product.store_attributes.id}`, 1)

        DBOpenRequest.onsuccess = function (event) {
          // store the result of opening the database in the db variable. This is used a lot below
          db = DBOpenRequest.result

          const id = product.id

          const objectStore = db
            .transaction(['products'], 'readwrite')
            .objectStore('products')

          const getProductToEdit = objectStore.get(id)

          getProductToEdit.onsuccess = () => {

            const updateProduct = objectStore.put({
              ...product,
              name: product.product_attributes.name,
              sku: product.product_attributes.sku
            })

            updateProduct.onsuccess = () => {
              resolve(product.product_attributes.name)
              console.log(`producto actualizado: ${product.product_attributes.name}`)
              // When this new request succeeds, run lunr to update product search
              context.dispatch('pos/handleLunr', product.store_attributes.id, {root:true})
              db.close()
            }
          }
        }
      })
    })
  },

  updateFirebaseToken() {
    if(localStorage.getItem('userData')) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      axios.post(`v1/users/${userData.id}/update_firebase_token`,{
        user: {
          firebase_token: sessionStorage.getItem('notifications_token')
        }
      })
    }
  }

}

export default {
  namespaced: true,
  actions,
}
