import axios from '@axios'

export default {
  namespaced: true,
  state: {
    payeeTaxes: {
      data: [],
      meta: {
        pagination: {

        },
      },
    }
  },
  getters: {
    payeeTaxes(state) {
      return state.payeeTaxes.data
    },
    pagination(state) {
      return state.payeeTaxes.meta.pagination
    },
    payeeCount: state => state.payeeTaxes.meta.pagination.total_objects,
  },
  mutations:{
    setPayeeTaxes(state, payload) {
      state.payeeTaxes = payload
    },
  },
  actions: {
    registerPayee(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/payee_tax_informations', params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPayeeTaxes(ctx, params) {
      return new Promise((resolve, reject) => {

        const commonUrl = params.by_establishment ? `/v1/payee_tax_informations?by_establishment=${params.by_establishment}` : '/v1/payee_tax_informations'; 
        const url = params.by_active_status && params.by_establishment ? `${commonUrl}&by_active_status=${params.by_active_status}` : params.by_active_status ? `${commonUrl}?by_active_status=${params.by_active_status}` : commonUrl;

        axios
          .get(url)
          .then(response => {
            ctx.commit('setPayeeTaxes', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editPayee(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/payee_tax_informations/${params.id}`, {
            payee_tax_information: {
              tax_id: params.tax_id,
              legal_name: params.legal_name,
              cfdi_use: params.cfdi_use.value,
              fiscal_regime: params.fiscal_regime.value,
              tax_zip_code: params.tax_zip_code,
              person_type: params.person_type,
              email: params.email,
              active_status: params.active_status,
            }
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deletePayee(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/payee_tax_informations/${id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}