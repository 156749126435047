import axios from '@axios'

export default {
  namespaced: true,

  actions: {
    addFacturamaInvoices(ctx, invoices) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/payments/add_invoices', {
            amount: invoices.amount,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
