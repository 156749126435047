export default [
  {
    path: '/deliveries',
    name: 'deliveries',
    component: () => import('@/views/deliveries/DeliveriesView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
