import axios from "@axios";

export default {
    namespaced: true,
    state: {
        general_plans: [],
        store_plan: [],
        subscription: null,
    },
     getters : {
        currentSubscription(state) {
            return state.subscription ? true : false
        },

        isSubscriptionActive(state) {
            return state.subscription && state.subscription.stripe_status === 'active' || state.subscription && state.subscription.stripe_status === 'trialing'
            
        }
    },
    mutations: {
      setPlans(state, payload) {
        const processedPlans = payload.map(plan => {
          plan.description = plan.description.split(',').map(item => {
            const words = item.trim().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1));
            return `• ${words.join(' ')}`;
          });
      
          plan.price = parseFloat(plan.price).toLocaleString('en-US', { style: 'decimal' });
      
          return plan;
        });
      
        state.store_plan = processedPlans.filter(plan => plan.plan_type === 'stores');
        state.general_plans = processedPlans.filter(plan => plan.plan_type === 'general');
      },
        setSubscription(state, payload) {
            state.subscription = payload;
        },
    },
    actions: {
        async fetchPlans({ commit }, _params) {
            try {
                const result = await axios.get("/v1/stripe/plans");
                await commit("setPlans", result.data);
            } catch (error) {
                console.error(error);
            }
        },
        fetchSubscription({ commit }, establisment_id) {
            return new Promise((resolve, reject) => {
                axios
                  .get(`/v1/stripe/subscription/${establisment_id}`)
                  .then(response => {
                    commit("setSubscription", response.data);
                    resolve(response.data)
                  })
                  .catch(error => {
                    reject(error)
                  })
            })
          
        },
    },
};
