/* eslint-disable camelcase */
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    emidaCommissionsInstances: {
      data: [],
      meta: {
        pagination: {

        },
      },
    },
    pagination: null,
  },
  getters: {
    emidaCommissionsInstances(state) {
      return state.emidaCommissionsInstances.data
    },
    pagination(state) {
      return state.emidaCommissionsInstances.meta.pagination
    },
  },
  mutations: {
    setEmidaComissionsInstances(state, payload) {
      state.emidaCommissionsInstances = payload
    },
  },
  actions: {
    fetchEmidaComissionsInstances(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/emida_commission_instances', {
            params,
          })
          .then(response => {
            ctx.commit('setEmidaComissionsInstances', response)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    collectComissionManually() {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/emida_commission_instances/charge_commissions')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEmidaComissions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/emida_commissions', {
            params,
          })
          .then(response => {
            ctx.commit('setEmidaComissionsInstances', response)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEmidaComission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/emida_commissions/${params}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addEmidaComission(ctx, emida_commission) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/emida_commissions', {
            emida_commission,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateEmidaComission(ctx, emida_commission) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/emida_commissions/${emida_commission.id}`, {
            emida_commission,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getEmidaConsolidationFile(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/emida_responses/generate_conciliation_file', {
            params,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
