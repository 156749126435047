import axios from "@axios"

export default {
  namespaced: true,
  state: {
    pendingOrder: null,
  },
  getters: {
    pendingOrder: (state) => state.pendingOrder,
  },
  mutations: {
    setPendingOrder(state, data) {
      state.pendingOrder = data
    },
  },
  actions: {
    dispatchPrivatePendingOrder(ctx, { order }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/pending_orders/private", {
            pending_order: {
              ...order,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    dispatchPendingOrder(ctx, { order }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v1/pending_orders/", {
            pending_order: {
              ...order,
            },
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchPendingOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/pending_orders/${params.id}`)
          .then((response) => {
            ctx.commit("setPendingOrder", response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
}
