/* eslint-disable */
/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class Deferred {
  constructor() {
    this.reject = () => { }
    this.resolve = () => { }
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  /**
     * Our API internals are not promiseified and cannot because our callback APIs have subtle expectations around
     * invoking promises inline, which Promises are forbidden to do. This method accepts an optional node-style callback
     * and returns a node-style callback which will resolve or reject the Deferred's promise.
     */
  wrapCallback(callback) {
    return (error, value) => {
      if (error) {
        this.reject(error)
      } else {
        this.resolve(value)
      }
      if (typeof callback === 'function') {
        // Attaching noop handler just in case developer wasn't expecting
        // promises
        this.promise.catch(() => { })
        // Some of our callbacks don't expect a value and our own tests
        // assert that the parameter length is 1
        if (callback.length === 1) {
          callback(error)
        } else {
          callback(error, value)
        }
      }
    }
  }
}

/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @fileoverview Standardized Firebase Error.
 *
 * Usage:
 *
 *   // Typescript string literals for type-safe codes
 *   type Err =
 *     'unknown' |
 *     'object-not-found'
 *     ;
 *
 *   // Closure enum for type-safe error codes
 *   // at-enum {string}
 *   var Err = {
 *     UNKNOWN: 'unknown',
 *     OBJECT_NOT_FOUND: 'object-not-found',
 *   }
 *
 *   let errors: Map<Err, string> = {
 *     'generic-error': "Unknown error",
 *     'file-not-found': "Could not find file: {$file}",
 *   };
 *
 *   // Type-safe function - must pass a valid error code as param.
 *   let error = new ErrorFactory<Err>('service', 'Service', errors);
 *
 *   ...
 *   throw error.create(Err.GENERIC);
 *   ...
 *   throw error.create(Err.FILE_NOT_FOUND, {'file': fileName});
 *   ...
 *   // Service: Could not file file: foo.txt (service/file-not-found).
 *
 *   catch (e) {
 *     assert(e.message === "Could not find file: foo.txt.");
 *     if (e.code === 'service/file-not-found') {
 *       console.log("Could not read file: " + e['file']);
 *     }
 *   }
 */
const ERROR_NAME = 'FirebaseError'
// Based on code from:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types
class FirebaseError extends Error {
  constructor(
    /** The error code for this error. */
    code, message,
    /** Custom data for this error. */
    customData,
  ) {
    super(message)
    this.code = code
    this.customData = customData
    /** The custom name for all FirebaseErrors. */
    this.name = ERROR_NAME
    // Fix For ES5
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, FirebaseError.prototype)
    // Maintains proper stack trace for where our error was thrown.
    // Only available on V8.
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorFactory.prototype.create)
    }
  }
}
class ErrorFactory {
  constructor(service, serviceName, errors) {
    this.service = service
    this.serviceName = serviceName
    this.errors = errors
  }

  create(code, ...data) {
    const customData = data[0] || {}
    const fullCode = `${this.service}/${code}`
    const template = this.errors[code]
    const message = template ? replaceTemplate(template, customData) : 'Error'
    // Service Name: Error message (service/code).
    const fullMessage = `${this.serviceName}: ${message} (${fullCode}).`
    const error = new FirebaseError(fullCode, fullMessage, customData)
    return error
  }
}
function replaceTemplate(template, data) {
  return template.replace(PATTERN, (_, key) => {
    const value = data[key]
    return value != null ? String(value) : `<${key}?>`
  })
}
const PATTERN = /\{\$([^}]+)}/g
/**
 * Deep equal two objects. Support Arrays and Objects.
 */
function deepEqual(a, b) {
  if (a === b) {
    return true
  }
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  for (const k of aKeys) {
    if (!bKeys.includes(k)) {
      return false
    }
    const aProp = a[k]
    const bProp = b[k]
    if (isObject(aProp) && isObject(bProp)) {
      if (!deepEqual(aProp, bProp)) {
        return false
      }
    } else if (aProp !== bProp) {
      return false
    }
  }
  for (const k of bKeys) {
    if (!aKeys.includes(k)) {
      return false
    }
  }
  return true
}
function isObject(thing) {
  return thing !== null && typeof thing === 'object'
}

/**
 * Component for service name T, e.g. `auth`, `auth-internal`
 */
class Component {
  /**
     *
     * @param name The public service name, e.g. app, auth, firestore, database
     * @param instanceFactory Service factory responsible for creating the public interface
     * @param type whether the service provided by the component is public or private
     */
  constructor(name, instanceFactory, type) {
    this.name = name
    this.instanceFactory = instanceFactory
    this.type = type
    this.multipleInstances = false
    /**
         * Properties to be added to the service namespace
         */
    this.serviceProps = {}
    this.instantiationMode = 'LAZY' /* LAZY */
    this.onInstanceCreated = null
  }

  setInstantiationMode(mode) {
    this.instantiationMode = mode
    return this
  }

  setMultipleInstances(multipleInstances) {
    this.multipleInstances = multipleInstances
    return this
  }

  setServiceProps(props) {
    this.serviceProps = props
    return this
  }

  setInstanceCreatedCallback(callback) {
    this.onInstanceCreated = callback
    return this
  }
}

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const DEFAULT_ENTRY_NAME$1 = '[DEFAULT]'

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Provider for instance for service name T, e.g. 'auth', 'auth-internal'
 * NameServiceMapping[T] is an alias for the type of the instance
 */
class Provider {
  constructor(name, container) {
    this.name = name
    this.container = container
    this.component = null
    this.instances = new Map()
    this.instancesDeferred = new Map()
    this.instancesOptions = new Map()
    this.onInitCallbacks = new Map()
  }

  /**
     * @param identifier A provider can provide mulitple instances of a service
     * if this.component.multipleInstances is true.
     */
  get(identifier) {
    // if multipleInstances is not supported, use the default name
    const normalizedIdentifier = this.normalizeInstanceIdentifier(identifier)
    if (!this.instancesDeferred.has(normalizedIdentifier)) {
      const deferred = new Deferred()
      this.instancesDeferred.set(normalizedIdentifier, deferred)
      if (this.isInitialized(normalizedIdentifier)
                || this.shouldAutoInitialize()) {
        // initialize the service if it can be auto-initialized
        try {
          const instance = this.getOrInitializeService({
            instanceIdentifier: normalizedIdentifier,
          })
          if (instance) {
            deferred.resolve(instance)
          }
        } catch (e) {
          // when the instance factory throws an exception during get(), it should not cause
          // a fatal error. We just return the unresolved promise in this case.
        }
      }
    }
    return this.instancesDeferred.get(normalizedIdentifier).promise
  }

  getImmediate(options) {
    let _a
    // if multipleInstances is not supported, use the default name
    const normalizedIdentifier = this.normalizeInstanceIdentifier(options === null || options === void 0 ? void 0 : options.identifier)
    const optional = (_a = options === null || options === void 0 ? void 0 : options.optional) !== null && _a !== void 0 ? _a : false
    if (this.isInitialized(normalizedIdentifier)
            || this.shouldAutoInitialize()) {
      try {
        return this.getOrInitializeService({
          instanceIdentifier: normalizedIdentifier,
        })
      } catch (e) {
        if (optional) {
          return null
        }


        return null
      }
    } else {
      // In case a component is not initialized and should/can not be auto-initialized at the moment, return null if the optional flag is set, or throw
      if (optional) {
        return null
      }

      throw Error(`Service ${this.name} is not available`)
    }
  }

  getComponent() {
    return this.component
  }

  setComponent(component) {
    if (component.name !== this.name) {
      throw Error(`Mismatching Component ${component.name} for Provider ${this.name}.`)
    }
    if (this.component) {
      throw Error(`Component for ${this.name} has already been provided`)
    }
    this.component = component
    // return early without attempting to initialize the component if the component requires explicit initialization (calling `Provider.initialize()`)
    if (!this.shouldAutoInitialize()) {
      return
    }
    // if the service is eager, initialize the default instance
    if (isComponentEager(component)) {
      try {
        this.getOrInitializeService({ instanceIdentifier: DEFAULT_ENTRY_NAME$1 })
      } catch (e) {
        // when the instance factory for an eager Component throws an exception during the eager
        // initialization, it should not cause a fatal error.
        // TODO: Investigate if we need to make it configurable, because some component may want to cause
        // a fatal error in this case?
      }
    }
    // Create service instances for the pending promises and resolve them
    // NOTE: if this.multipleInstances is false, only the default instance will be created
    // and all promises with resolve with it regardless of the identifier.
    for (const [instanceIdentifier, instanceDeferred] of this.instancesDeferred.entries()) {
      const normalizedIdentifier = this.normalizeInstanceIdentifier(instanceIdentifier)
      try {
        // `getOrInitializeService()` should always return a valid instance since a component is guaranteed. use ! to make typescript happy.
        const instance = this.getOrInitializeService({
          instanceIdentifier: normalizedIdentifier,
        })
        instanceDeferred.resolve(instance)
      } catch (e) {
        // when the instance factory throws an exception, it should not cause
        // a fatal error. We just leave the promise unresolved.
      }
    }
  }

  clearInstance(identifier = DEFAULT_ENTRY_NAME$1) {
    this.instancesDeferred.delete(identifier)
    this.instancesOptions.delete(identifier)
    this.instances.delete(identifier)
  }

  // app.delete() will call this method on every provider to delete the services
  // TODO: should we mark the provider as deleted?
  async delete() {
    const services = Array.from(this.instances.values())
    await Promise.all([
      ...services
        .filter(service => 'INTERNAL' in service) // legacy services
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map(service => service.INTERNAL.delete()),
      ...services
        .filter(service => '_delete' in service) // modularized services
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map(service => service._delete()),
    ])
  }

  isComponentSet() {
    return this.component != null
  }

  isInitialized(identifier = DEFAULT_ENTRY_NAME$1) {
    return this.instances.has(identifier)
  }

  getOptions(identifier = DEFAULT_ENTRY_NAME$1) {
    return this.instancesOptions.get(identifier) || {}
  }

  initialize(opts = {}) {
    const { options = {} } = opts
    const normalizedIdentifier = this.normalizeInstanceIdentifier(opts.instanceIdentifier)
    if (this.isInitialized(normalizedIdentifier)) {
      throw Error(`${this.name}(${normalizedIdentifier}) has already been initialized`)
    }
    if (!this.isComponentSet()) {
      throw Error(`Component ${this.name} has not been registered yet`)
    }
    const instance = this.getOrInitializeService({
      instanceIdentifier: normalizedIdentifier,
      options,
    })
    // resolve any pending promise waiting for the service instance
    for (const [instanceIdentifier, instanceDeferred] of this.instancesDeferred.entries()) {
      const normalizedDeferredIdentifier = this.normalizeInstanceIdentifier(instanceIdentifier)
      if (normalizedIdentifier === normalizedDeferredIdentifier) {
        instanceDeferred.resolve(instance)
      }
    }
    return instance
  }

  /**
     *
     * @param callback - a function that will be invoked  after the provider has been initialized by calling provider.initialize().
     * The function is invoked SYNCHRONOUSLY, so it should not execute any longrunning tasks in order to not block the program.
     *
     * @param identifier An optional instance identifier
     * @returns a function to unregister the callback
     */
  onInit(callback, identifier) {
    let _a
    const normalizedIdentifier = this.normalizeInstanceIdentifier(identifier)
    const existingCallbacks = (_a = this.onInitCallbacks.get(normalizedIdentifier)) !== null && _a !== void 0 ? _a : new Set()
    existingCallbacks.add(callback)
    this.onInitCallbacks.set(normalizedIdentifier, existingCallbacks)
    const existingInstance = this.instances.get(normalizedIdentifier)
    if (existingInstance) {
      callback(existingInstance, normalizedIdentifier)
    }
    return () => {
      existingCallbacks.delete(callback)
    }
  }

  /**
     * Invoke onInit callbacks synchronously
     * @param instance the service instance`
     */
  invokeOnInitCallbacks(instance, identifier) {
    const callbacks = this.onInitCallbacks.get(identifier)
    if (!callbacks) {
      return
    }
    for (const callback of callbacks) {
      try {
        callback(instance, identifier)
      } catch (_a) {
        // ignore errors in the onInit callback
      }
    }
  }

  getOrInitializeService({ instanceIdentifier, options = {} }) {
    let instance = this.instances.get(instanceIdentifier)
    if (!instance && this.component) {
      instance = this.component.instanceFactory(this.container, {
        instanceIdentifier: normalizeIdentifierForFactory(instanceIdentifier),
        options,
      })
      this.instances.set(instanceIdentifier, instance)
      this.instancesOptions.set(instanceIdentifier, options)
      /**
             * Invoke onInit listeners.
             * Note this.component.onInstanceCreated is different, which is used by the component creator,
             * while onInit listeners are registered by consumers of the provider.
             */
      this.invokeOnInitCallbacks(instance, instanceIdentifier)
      /**
             * Order is important
             * onInstanceCreated() should be called after this.instances.set(instanceIdentifier, instance); which
             * makes `isInitialized()` return true.
             */
      if (this.component.onInstanceCreated) {
        try {
          this.component.onInstanceCreated(this.container, instanceIdentifier, instance)
        } catch (_a) {
          // ignore errors in the onInstanceCreatedCallback
        }
      }
    }
    return instance || null
  }

  normalizeInstanceIdentifier(identifier = DEFAULT_ENTRY_NAME$1) {
    if (this.component) {
      return this.component.multipleInstances ? identifier : DEFAULT_ENTRY_NAME$1
    }

    return identifier // assume multiple instances are supported before the component is provided.
  }

  shouldAutoInitialize() {
    return (!!this.component
            && this.component.instantiationMode !== 'EXPLICIT' /* EXPLICIT */)
  }
}
// undefined should be passed to the service factory for the default instance
function normalizeIdentifierForFactory(identifier) {
  return identifier === DEFAULT_ENTRY_NAME$1 ? undefined : identifier
}
function isComponentEager(component) {
  return component.instantiationMode === 'EAGER' /* EAGER */
}

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * ComponentContainer that provides Providers for service name T, e.g. `auth`, `auth-internal`
 */
class ComponentContainer {
  constructor(name) {
    this.name = name
    this.providers = new Map()
  }

  /**
     *
     * @param component Component being added
     * @param overwrite When a component with the same name has already been registered,
     * if overwrite is true: overwrite the existing component with the new component and create a new
     * provider with the new component. It can be useful in tests where you want to use different mocks
     * for different tests.
     * if overwrite is false: throw an exception
     */
  addComponent(component) {
    const provider = this.getProvider(component.name)
    if (provider.isComponentSet()) {
      throw new Error(`Component ${component.name} has already been registered with ${this.name}`)
    }
    provider.setComponent(component)
  }

  addOrOverwriteComponent(component) {
    const provider = this.getProvider(component.name)
    if (provider.isComponentSet()) {
      // delete the existing provider from the container, so we can register the new component
      this.providers.delete(component.name)
    }
    this.addComponent(component)
  }

  /**
     * getProvider provides a type safe interface where it can only be called with a field name
     * present in NameServiceMapping interface.
     *
     * Firebase SDKs providing services should extend NameServiceMapping interface to register
     * themselves.
     */
  getProvider(name) {
    if (this.providers.has(name)) {
      return this.providers.get(name)
    }
    // create a Provider for a service that hasn't registered with Firebase
    const provider = new Provider(name, this)
    this.providers.set(name, provider)
    return provider
  }

  getProviders() {
    return Array.from(this.providers.values())
  }
}

/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * A container for all of the Logger instances
 */
const instances = []
/**
 * The JS SDK supports 5 log levels and also allows a user the ability to
 * silence the logs altogether.
 *
 * The order is a follows:
 * DEBUG < VERBOSE < INFO < WARN < ERROR
 *
 * All of the log types above the current log level will be captured (i.e. if
 * you set the log level to `INFO`, errors will still be logged, but `DEBUG` and
 * `VERBOSE` logs will not)
 */
let LogLevel;
(function (LogLevel) {
  LogLevel[LogLevel.DEBUG = 0] = 'DEBUG'
  LogLevel[LogLevel.VERBOSE = 1] = 'VERBOSE'
  LogLevel[LogLevel.INFO = 2] = 'INFO'
  LogLevel[LogLevel.WARN = 3] = 'WARN'
  LogLevel[LogLevel.ERROR = 4] = 'ERROR'
  LogLevel[LogLevel.SILENT = 5] = 'SILENT'
}(LogLevel || (LogLevel = {})))
const levelStringToEnum = {
  debug: LogLevel.DEBUG,
  verbose: LogLevel.VERBOSE,
  info: LogLevel.INFO,
  warn: LogLevel.WARN,
  error: LogLevel.ERROR,
  silent: LogLevel.SILENT,
}
/**
 * The default log level
 */
const defaultLogLevel = LogLevel.INFO
/**
 * By default, `console.debug` is not displayed in the developer console (in
 * chrome). To avoid forcing users to have to opt-in to these logs twice
 * (i.e. once for firebase, and once in the console), we are sending `DEBUG`
 * logs to the `console.log` function.
 */
const ConsoleMethod = {
  [LogLevel.DEBUG]: 'log',
  [LogLevel.VERBOSE]: 'log',
  [LogLevel.INFO]: 'info',
  [LogLevel.WARN]: 'warn',
  [LogLevel.ERROR]: 'error',
}
/**
 * The default log handler will forward DEBUG, VERBOSE, INFO, WARN, and ERROR
 * messages on to their corresponding console counterparts (if the log method
 * is supported by the current log level)
 */
const defaultLogHandler = (instance, logType, ...args) => {
  if (logType < instance.logLevel) {
    return
  }
  const now = new Date().toISOString()
  const method = ConsoleMethod[logType]
  if (method) {
    console[method](`[${now}]  ${instance.name}:`, ...args)
  } else {
    throw new Error(`Attempted to log a message with an invalid logType (value: ${logType})`)
  }
}
class Logger {
  /**
     * Gives you an instance of a Logger to capture messages according to
     * Firebase's logging scheme.
     *
     * @param name The name that the logs will be associated with
     */
  constructor(name) {
    this.name = name
    /**
         * The log level of the given Logger instance.
         */
    this._logLevel = defaultLogLevel
    /**
         * The main (internal) log handler for the Logger instance.
         * Can be set to a new function in internal package code but not by user.
         */
    this._logHandler = defaultLogHandler
    /**
         * The optional, additional, user-defined log handler for the Logger instance.
         */
    this._userLogHandler = null
    /**
         * Capture the current instance for later use
         */
    instances.push(this)
  }

  get logLevel() {
    return this._logLevel
  }

  set logLevel(val) {
    if (!(val in LogLevel)) {
      throw new TypeError(`Invalid value "${val}" assigned to \`logLevel\``)
    }
    this._logLevel = val
  }

  // Workaround for setter/getter having to be the same type.
  setLogLevel(val) {
    this._logLevel = typeof val === 'string' ? levelStringToEnum[val] : val
  }

  get logHandler() {
    return this._logHandler
  }

  set logHandler(val) {
    if (typeof val !== 'function') {
      throw new TypeError('Value assigned to `logHandler` must be a function')
    }
    this._logHandler = val
  }

  get userLogHandler() {
    return this._userLogHandler
  }

  set userLogHandler(val) {
    this._userLogHandler = val
  }

  /**
     * The functions below are all based on the `console` interface
     */
  debug(...args) {
    this._userLogHandler && this._userLogHandler(this, LogLevel.DEBUG, ...args)
    this._logHandler(this, LogLevel.DEBUG, ...args)
  }

  log(...args) {
    this._userLogHandler
            && this._userLogHandler(this, LogLevel.VERBOSE, ...args)
    this._logHandler(this, LogLevel.VERBOSE, ...args)
  }

  info(...args) {
    this._userLogHandler && this._userLogHandler(this, LogLevel.INFO, ...args)
    this._logHandler(this, LogLevel.INFO, ...args)
  }

  warn(...args) {
    this._userLogHandler && this._userLogHandler(this, LogLevel.WARN, ...args)
    this._logHandler(this, LogLevel.WARN, ...args)
  }

  error(...args) {
    this._userLogHandler && this._userLogHandler(this, LogLevel.ERROR, ...args)
    this._logHandler(this, LogLevel.ERROR, ...args)
  }
}
function setLogLevel$1(level) {
  instances.forEach(inst => {
    inst.setLogLevel(level)
  })
}
function setUserLogHandler(logCallback, options) {
  for (const instance of instances) {
    let customLogLevel = null
    if (options && options.level) {
      customLogLevel = levelStringToEnum[options.level]
    }
    if (logCallback === null) {
      instance.userLogHandler = null
    } else {
      instance.userLogHandler = (instance, level, ...args) => {
        const message = args
          .map(arg => {
            if (arg == null) {
              return null
            }
            if (typeof arg === 'string') {
              return arg
            }
            if (typeof arg === 'number' || typeof arg === 'boolean') {
              return arg.toString()
            }
            if (arg instanceof Error) {
              return arg.message
            }

            try {
              return JSON.stringify(arg)
            } catch (ignored) {
              return null
            }
          })
          .filter(arg => arg)
          .join(' ')
        if (level >= (customLogLevel !== null && customLogLevel !== void 0 ? customLogLevel : instance.logLevel)) {
          logCallback({
            level: LogLevel[level].toLowerCase(),
            message,
            args,
            type: instance.name,
          })
        }
      }
    }
  }
}

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class PlatformLoggerServiceImpl {
  constructor(container) {
    this.container = container
  }

  // In initial implementation, this will be called by installations on
  // auth token refresh, and installations will send this string.
  getPlatformInfoString() {
    const providers = this.container.getProviders()
    // Loop through providers and get library/version pairs from any that are
    // version components.
    return providers
      .map(provider => {
        if (isVersionServiceProvider(provider)) {
          const service = provider.getImmediate()
          return `${service.library}/${service.version}`
        }

        return null
      })
      .filter(logString => logString)
      .join(' ')
  }
}
/**
 *
 * @param provider check if this provider provides a VersionService
 *
 * NOTE: Using Provider<'app-version'> is a hack to indicate that the provider
 * provides VersionService. The provider is not necessarily a 'app-version'
 * provider.
 */
function isVersionServiceProvider(provider) {
  const component = provider.getComponent()
  return (component === null || component === void 0 ? void 0 : component.type) === 'VERSION' /* VERSION */
}

const name$o = 'https://www.gstatic.com/firebasejs/9.6.2/firebase-app.js'
const version$1 = '0.7.12'

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const logger = new Logger('https://www.gstatic.com/firebasejs/9.6.2/firebase-app.js')

const name$n = 'https://www.gstatic.com/firebasejs/9.6.2/firebase-app.js-compat'

const name$m = '@firebase/analytics-compat'

const name$l = '@firebase/analytics'

const name$k = 'https://www.gstatic.com/firebasejs/9.6.2/firebase-app.js-check-compat'

const name$j = 'https://www.gstatic.com/firebasejs/9.6.2/firebase-app.js-check'

const name$i = '@firebase/auth'

const name$h = '@firebase/auth-compat'

const name$g = '@firebase/database'

const name$f = '@firebase/database-compat'

const name$e = '@firebase/functions'

const name$d = '@firebase/functions-compat'

const name$c = '@firebase/installations'

const name$b = '@firebase/installations-compat'

const name$a = '@firebase/messaging'

const name$9 = '@firebase/messaging-compat'

const name$8 = '@firebase/performance'

const name$7 = '@firebase/performance-compat'

const name$6 = '@firebase/remote-config'

const name$5 = '@firebase/remote-config-compat'

const name$4 = '@firebase/storage'

const name$3 = '@firebase/storage-compat'

const name$2 = '@firebase/firestore'

const name$1 = '@firebase/firestore-compat'

const name$p = 'firebase'
const version$2 = '9.6.2'

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * The default app name
 *
 * @internal
 */
const DEFAULT_ENTRY_NAME = '[DEFAULT]'
const PLATFORM_LOG_STRING = {
  [name$o]: 'fire-core',
  [name$n]: 'fire-core-compat',
  [name$l]: 'fire-analytics',
  [name$m]: 'fire-analytics-compat',
  [name$j]: 'fire-app-check',
  [name$k]: 'fire-app-check-compat',
  [name$i]: 'fire-auth',
  [name$h]: 'fire-auth-compat',
  [name$g]: 'fire-rtdb',
  [name$f]: 'fire-rtdb-compat',
  [name$e]: 'fire-fn',
  [name$d]: 'fire-fn-compat',
  [name$c]: 'fire-iid',
  [name$b]: 'fire-iid-compat',
  [name$a]: 'fire-fcm',
  [name$9]: 'fire-fcm-compat',
  [name$8]: 'fire-perf',
  [name$7]: 'fire-perf-compat',
  [name$6]: 'fire-rc',
  [name$5]: 'fire-rc-compat',
  [name$4]: 'fire-gcs',
  [name$3]: 'fire-gcs-compat',
  [name$2]: 'fire-fst',
  [name$1]: 'fire-fst-compat',
  'fire-js': 'fire-js',
  [name$p]: 'fire-js-all',
}

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * @internal
 */
const _apps = new Map()
/**
 * Registered components.
 *
 * @internal
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _components = new Map()
/**
 * @param component - the component being added to this app's container
 *
 * @internal
 */
function _addComponent(app, component) {
  try {
    app.container.addComponent(component)
  } catch (e) {
    logger.debug(`Component ${component.name} failed to register with FirebaseApp ${app.name}`, e)
  }
}
/**
 *
 * @internal
 */
function _addOrOverwriteComponent(app, component) {
  app.container.addOrOverwriteComponent(component)
}
/**
 *
 * @param component - the component to register
 * @returns whether or not the component is registered successfully
 *
 * @internal
 */
function _registerComponent(component) {
  const componentName = component.name
  if (_components.has(componentName)) {
    logger.debug(`There were multiple attempts to register component ${componentName}.`)
    return false
  }
  _components.set(componentName, component)
  // add the component to existing app instances
  for (const app of _apps.values()) {
    _addComponent(app, component)
  }
  return true
}
/**
 *
 * @param app - FirebaseApp instance
 * @param name - service name
 *
 * @returns the provider for the service with the matching name
 *
 * @internal
 */
function _getProvider(app, name) {
  return app.container.getProvider(name)
}
/**
 *
 * @param app - FirebaseApp instance
 * @param name - service name
 * @param instanceIdentifier - service instance identifier in case the service supports multiple instances
 *
 * @internal
 */
function _removeServiceInstance(app, name, instanceIdentifier = DEFAULT_ENTRY_NAME) {
  _getProvider(app, name).clearInstance(instanceIdentifier)
}
/**
 * Test only
 *
 * @internal
 */
function _clearComponents() {
  _components.clear()
}

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
const ERRORS = {
  ['no-app' /* NO_APP */]: "No Firebase App '{$appName}' has been created - "
        + 'call Firebase App.initializeApp()',
  ['bad-app-name' /* BAD_APP_NAME */]: "Illegal App name: '{$appName}",
  ['duplicate-app' /* DUPLICATE_APP */]: "Firebase App named '{$appName}' already exists with different options or config",
  ['app-deleted' /* APP_DELETED */]: "Firebase App named '{$appName}' already deleted",
  ['invalid-app-argument' /* INVALID_APP_ARGUMENT */]: 'firebase.{$appName}() takes either no argument or a '
        + 'Firebase App instance.',
  ['invalid-log-argument' /* INVALID_LOG_ARGUMENT */]: 'First argument to `onLog` must be null or a function.',
}
const ERROR_FACTORY = new ErrorFactory('app', 'Firebase', ERRORS)

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
class FirebaseAppImpl {
  constructor(options, config, container) {
    this._isDeleted = false
    this._options = { ...options }
    this._config = { ...config }
    this._name = config.name
    this._automaticDataCollectionEnabled = config.automaticDataCollectionEnabled
    this._container = container
    this.container.addComponent(new Component('app', () => this, 'PUBLIC' /* PUBLIC */))
  }

  get automaticDataCollectionEnabled() {
    this.checkDestroyed()
    return this._automaticDataCollectionEnabled
  }

  set automaticDataCollectionEnabled(val) {
    this.checkDestroyed()
    this._automaticDataCollectionEnabled = val
  }

  get name() {
    this.checkDestroyed()
    return this._name
  }

  get options() {
    this.checkDestroyed()
    return this._options
  }

  get config() {
    this.checkDestroyed()
    return this._config
  }

  get container() {
    return this._container
  }

  get isDeleted() {
    return this._isDeleted
  }

  set isDeleted(val) {
    this._isDeleted = val
  }

  /**
     * This function will throw an Error if the App has already been deleted -
     * use before performing API actions on the App.
     */
  checkDestroyed() {
    if (this.isDeleted) {
      throw ERROR_FACTORY.create('app-deleted' /* APP_DELETED */, { appName: this._name })
    }
  }
}

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * The current SDK version.
 *
 * @public
 */
const SDK_VERSION = version$2
function initializeApp(options, rawConfig = {}) {
  if (typeof rawConfig !== 'object') {
    const name = rawConfig
    rawConfig = { name }
  }
  const config = { name: DEFAULT_ENTRY_NAME, automaticDataCollectionEnabled: false, ...rawConfig }
  const { name } = config
  if (typeof name !== 'string' || !name) {
    throw ERROR_FACTORY.create('bad-app-name' /* BAD_APP_NAME */, {
      appName: String(name),
    })
  }
  const existingApp = _apps.get(name)
  if (existingApp) {
    // return the existing app if options and config deep equal the ones in the existing app.
    if (deepEqual(options, existingApp.options)
            && deepEqual(config, existingApp.config)) {
      return existingApp
    }

    throw ERROR_FACTORY.create('duplicate-app' /* DUPLICATE_APP */, { appName: name })
  }
  const container = new ComponentContainer(name)
  for (const component of _components.values()) {
    container.addComponent(component)
  }
  const newApp = new FirebaseAppImpl(options, config, container)
  _apps.set(name, newApp)
  return newApp
}
/**
 * Retrieves a {@link https://www.gstatic.com/firebasejs/9.6.2/firebase-app.js#FirebaseApp} instance.
 *
 * When called with no arguments, the default app is returned. When an app name
 * is provided, the app corresponding to that name is returned.
 *
 * An exception is thrown if the app being retrieved has not yet been
 * initialized.
 *
 * @example
 * ```javascript
 * // Return the default app
 * const app = getApp();
 * ```
 *
 * @example
 * ```javascript
 * // Return a named app
 * const otherApp = getApp("otherApp");
 * ```
 *
 * @param name - Optional name of the app to return. If no name is
 *   provided, the default is `"[DEFAULT]"`.
 *
 * @returns The app corresponding to the provided app name.
 *   If no app name is provided, the default app is returned.
 *
 * @public
 */
function getApp(name = DEFAULT_ENTRY_NAME) {
  const app = _apps.get(name)
  if (!app) {
    throw ERROR_FACTORY.create('no-app' /* NO_APP */, { appName: name })
  }
  return app
}
/**
 * A (read-only) array of all initialized apps.
 * @public
 */
function getApps() {
  return Array.from(_apps.values())
}
/**
 * Renders this app unusable and frees the resources of all associated
 * services.
 *
 * @example
 * ```javascript
 * deleteApp(app)
 *   .then(function() {
 *     console.log("App deleted successfully");
 *   })
 *   .catch(function(error) {
 *     console.log("Error deleting app:", error);
 *   });
 * ```
 *
 * @public
 */
async function deleteApp(app) {
  const { name } = app
  if (_apps.has(name)) {
    _apps.delete(name)
    await Promise.all(app.container
      .getProviders()
      .map(provider => provider.delete()))
    app.isDeleted = true
  }
}
/**
 * Registers a library's name and version for platform logging purposes.
 * @param library - Name of 1p or 3p library (e.g. firestore, angularfire)
 * @param version - Current version of that library.
 * @param variant - Bundle variant, e.g., node, rn, etc.
 *
 * @public
 */
function registerVersion(libraryKeyOrName, version, variant) {
  let _a
  // TODO: We can use this check to whitelist strings when/if we set up
  // a good whitelist system.
  let library = (_a = PLATFORM_LOG_STRING[libraryKeyOrName]) !== null && _a !== void 0 ? _a : libraryKeyOrName
  if (variant) {
    library += `-${variant}`
  }
  const libraryMismatch = library.match(/\s|\//)
  const versionMismatch = version.match(/\s|\//)
  if (libraryMismatch || versionMismatch) {
    const warning = [
      `Unable to register library "${library}" with version "${version}":`,
    ]
    if (libraryMismatch) {
      warning.push(`library name "${library}" contains illegal characters (whitespace or "/")`)
    }
    if (libraryMismatch && versionMismatch) {
      warning.push('and')
    }
    if (versionMismatch) {
      warning.push(`version name "${version}" contains illegal characters (whitespace or "/")`)
    }
    logger.warn(warning.join(' '))
    return
  }
  _registerComponent(new Component(`${library}-version`, () => ({ library, version }), 'VERSION' /* VERSION */))
}
/**
 * Sets log handler for all Firebase SDKs.
 * @param logCallback - An optional custom log handler that executes user code whenever
 * the Firebase SDK makes a logging call.
 *
 * @public
 */
function onLog(logCallback, options) {
  if (logCallback !== null && typeof logCallback !== 'function') {
    throw ERROR_FACTORY.create('invalid-log-argument' /* INVALID_LOG_ARGUMENT */)
  }
  setUserLogHandler(logCallback, options)
}
/**
 * Sets log level for all Firebase SDKs.
 *
 * All of the log types above the current log level are captured (i.e. if
 * you set the log level to `info`, errors are logged, but `debug` and
 * `verbose` logs are not).
 *
 * @public
 */
function setLogLevel(logLevel) {
  setLogLevel$1(logLevel)
}

/**
 * @license
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function registerCoreComponents(variant) {
  _registerComponent(new Component('platform-logger', container => new PlatformLoggerServiceImpl(container), 'PRIVATE' /* PRIVATE */))
  // Register `app` package.
  registerVersion(name$o, version$1, variant)
  // BUILD_TARGET will be replaced by values like esm5, esm2017, cjs5, etc during the compilation
  registerVersion(name$o, version$1, 'esm2017')
  // Register platform SDK identifier (no version).
  registerVersion('fire-js', '')
}

/**
 * Firebase App
 *
 * @remarks This package coordinates the communication between the different Firebase components
 * @packageDocumentation
 */
registerCoreComponents('')

const name = 'firebase'
const version = '9.6.2'

/**
 * @license
 * Copyright 2020 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
registerVersion(name, version, 'cdn')

export {
  FirebaseError, SDK_VERSION, DEFAULT_ENTRY_NAME as _DEFAULT_ENTRY_NAME, _addComponent, _addOrOverwriteComponent, _apps, _clearComponents, _components, _getProvider, _registerComponent, _removeServiceInstance, deleteApp, getApp, getApps, initializeApp, onLog, registerVersion, setLogLevel,
}

// # sourceMappingURL=firebase-app.js.map
