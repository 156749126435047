export default [
  {
    path: '/emida-comissions/instances',
    name: 'emida-commission-instances',
    component: () => import('@/views/emida/EmidaComissionInstancesList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/emida-comissions/general',
    name: 'emida-commission-general',
    component: () => import('@/views/emida/EmidaComissionsGeneralList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ventas-emida/recargas-&-pago-de-servicios',
    name: 'emida-recharges-report',
    component: () => import('@/views/emida/EmidaRechargesReportList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
