export default [
  {
    path: '/suppliers/list',
    name: 'suppliers-list',
    component: () => import('@/views/suppliers/SuppliersList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
