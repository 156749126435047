import lunr from "lunr"
import Localbase from "localbase"
import axios from "axios"
import promotions from "@/store/promotions"
import {
  getBestPromotion,
  getPromoTypeAndValue,
} from "@/@core/utils/store-product-utils"

const state = () => ({
  loading: false,
  openShoppingCartModal: false,
  categories: [],
  products: [],
  offlineProducts: {},
  lunrCtx: null,
  cart: [],
  showCartModal: false,
  productPromotion: null,
  sales: [],
  offlineMode: true,
  isInventoryMode: false,
  settings: {
    showCategories: true,
    showCamera: false,
  },
  restockMode: false,
  supplierPaymentMode: false,
  cashWithdrawalMode: false,
  servicePaymentMode: false,
  loanPaymentMode: false,
  airtimeRechargeMode: false,
  productInventoryMode: false,
  promoValidationMode: false,
  currentRestockSupplier: null,
  starredProducts: true,
  cashPaymentMode: false,
  bookStoreModal: false,
  newCartItem: null,
  bookReferences: [],
  bookWithName: [],
  selectedProduct: null,
  booksWithReference: [],
  codiPaymentMode: false,
  bankDepositMode: false,
})

const getters = {
  loading(state) {
    return state.loading
  },
  offlineMode(state) {
    return state.offlineMode
  },
  bookWithName(state) {
    return state.bookWithName
  },
  categories(state) {
    return state.categories
  },
  cart(state) {
    return state.cart
  },
  showCartModal(state) {
    return state.showCartModal
  },
  productPromotion(state) {
    return state.productPromotion
  },
  settings(state) {
    return state.settings
  },
  starredProducts(state) {
    return state.starredProducts
  },

  cartTotalNoPromotionApplied(state) {
    let total = 0
    state.cart.forEach((product) => {
      if (state.restockMode != true) {
        total += product.unit_price * product.units
      } else {
        total += product.unit_cost * product.units
      }
    })
    return total
  },
  cartTotal(state) {
    var total = 0
    state.cart.forEach((product) => {
      let product_total = []
      const index = state.cart.indexOf(product)
      if (state.restockMode === true) {
        product_total.push(product.unit_cost * product.units)
      } else {
        if (product.promotion_attributes.length > 0) {
          const product_totals = []
          const units = state.cart[index].units
          product.promotion_attributes.forEach((promotion) => {
            if (
              units >= promotion.needs_to_buy &&
              promotion.is_canceled === false &&
              promotion.is_published === true &&
              Date.now() / 1000 >
                new Date(promotion.begins_at).getTime() / 1000 &&
              Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
            ) {
              const unitsToNeedsToBuyRatio = Math.floor(
                state.cart[index].units / promotion.needs_to_buy
              )
              const product_total_temp =
                promotion.promo_type === "reward_points"
                  ? state.cart[index].unit_price * state.cart[index].units
                  : promotion.promo_type === "price_discount"
                  ? state.cart[index].unit_price *
                    (1 - promotion.discount / 100) *
                    state.cart[index].units
                  : promotion.promo_type === "free_product"
                  ? state.cart[index].unit_price *
                    (state.cart[index].units -
                      unitsToNeedsToBuyRatio * promotion.gets_you)
                  : state.cart[index].unit_price * state.cart[index].units

              product_totals.push(product_total_temp)
            } else {
              product_totals.push(
                state.cart[index].unit_price * state.cart[index].units
              )
            }
          })
          product_total.push(Math.min(...product_totals))
        } else if (promotions.state.promoCodePromotion != null) {
          product_total.push(
            state.cart[index].unit_price *
              (1 - promotions.state.promoCodePromotion.discount / 100) *
              state.cart[index].units
          )
        } else {
          product_total.push(
            state.cart[index].unit_price * state.cart[index].units
          )
        }
      }
      total += product_total[0]
    })
    return total
  },
  cartTotalProducts(state) {
    let total = 0
    state.cart.forEach((product) => {
      if (product.product_attributes.is_weighted) {
        total += 1
      } else {
        total += Number(product.units)
      }
    })
    return total
  },
  inventoryCartTotal(state) {
    let totalOfInventory = 0
    state.cart.forEach((product) => {
      totalOfInventory += product.unit_cost * product.units
    })
    return totalOfInventory
  },
  isInventoryMode(state) {
    return state.isInventoryMode
  },
  lunrCtx(state) {
    return state.lunrCtx
  },
  offlineProducts(state) {
    return state.offlineProducts
  },
  restockMode(state) {
    return state.restockMode
  },
  cashWithdrawalMode(state) {
    return state.cashWithdrawalMode
  },
  supplierPaymentMode(state) {
    return state.supplierPaymentMode
  },
  servicePaymentMode(state) {
    return state.servicePaymentMode
  },
  cashPaymentMode(state) {
    return state.cashPaymentMode
  },
  loanPaymentMode(state) {
    return state.loanPaymentMode
  },
  airtimeRechargeMode(state) {
    return state.airtimeRechargeMode
  },
  productInventoryMode(state) {
    return state.productInventoryMode
  },
  promoValidationMode(state) {
    return state.promoValidationMode
  },
  bankDepositMode(state) {
    return state.bankDepositMode
  },
  codiPaymentMode(state) {
    return state.codiPaymentMode
  },
  currentRestockSupplier(state) {
    return state.currentRestockSupplier
  },
  bookStoreModal(state) {
    return state.bookStoreModal
  },
  newCartItem(state) {
    return state.newCartItem
  },
  bookReferences(state) {
    return state.bookReferences
  },
  booksWithReference(state) {
    return state.booksWithReference
  },
  selectedProduct(state) {
    return state.selectedProduct
  },
}

const mutations = {
  loading: (state, payload) => {
    state.loading = payload
  },
  pushProductToCart: (state, payload) => {
    state.cart.unshift(payload.data[0])
    state.newCartItem = payload.data[0]
  },
  pushPromotions: (state, payload) => {
    state.productPromotion = payload.data
  },
  deletePromotions: (state) => {
    state.productPromotion = null
  },
  incrementProductQuantity: (state, { cartItem, units_to_add }) => {
    const index = state.cart.indexOf(cartItem)
    state.cart[index].units =
      Number(state.cart[index].units) + Number(units_to_add)
    state.newCartItem = cartItem
  },
  incrementOneProductQuantity: (state, cartItem) => {
    const index = state.cart.indexOf(cartItem)
    state.cart[index].units++
  },
  calculateProductTotal: (state, { cartItem, product_total }) => {
    const index = state.cart.indexOf(cartItem)
    state.cart[index].product_total = product_total
  },
  decrementProductQuantity: (state, cartItem) => {
    const index = state.cart.indexOf(cartItem)
    if (state.cart[index].units > 1) {
      state.cart[index].units--
    }
  },
  setShowCartModal: (state, payload) => {
    state.showCartModal = payload
  },
  setProductQuantity: (state, { cartItem, units }) => {
    const index = state.cart.indexOf(cartItem)
    state.cart[index].units = units
  },
  removeOneCartItem: (state, cartItem) => {
    const cartItemIndex = state.cart.indexOf(cartItem)
    if (state.cart[cartItemIndex].units === 1) {
      state.cart.splice(cartItemIndex, 1)
    } else {
      state.cart[cartItemIndex].units--
    }
  },
  setProductWeight: (state, { cartItem, units }) => {
    state.cart.forEach((product) => {
      if (product.id === cartItem.id) product.units = units
    })
  },
  deleteProductFromCarts: (state, cartItem) => {
    const index = state.cart.indexOf(cartItem)
    if (index > -1) {
      state.cart.splice(index, 1)
    }
  },
  deleteBooksFromCart: (state, cartItem) => {
    const index = state.cart.indexOf(cartItem)

    if (index > -1) {
      state.cart[index].units === 1
        ? state.cart.splice(index, 1)
        : state.cart[index].units--
    }
    state.bookWithName = state.bookWithName.filter(
      (i) => i.reference !== cartItem.reference
    )

    state.bookStoreModal = false
    state.bookReferences = []
  },
  addBookReference: (state, payload) => {
    state.bookReferences.push(payload)
  },
  deleteBookReference: (state, payload) => {
    state.bookReferences = state.bookReferences.filter((i) => i !== payload)
  },
  deleteSingleBooksWithReference: (state, payload) => {
    const bookReference = state.booksWithReference.find(
      (i) =>
        i.reference === payload.reference &&
        i.storeProducts.hasOwnProperty(payload.productId)
    )

    if (bookReference) {
      delete bookReference.storeProducts[payload.productId]

      if (Object.keys(bookReference.storeProducts).length === 0) {
        state.booksWithReference = state.booksWithReference.filter(
          (i) => i.reference !== payload.reference
        )
      }
    }
  },
  deleteBookName: (state, payload) => {
    const bookName = state.bookWithName.find((i) => i.reference === payload)

    if (bookName) {
      const storeProductKeys = Object.keys(bookName.storeProducts)
      storeProductKeys.length > 1
        ? delete bookName.storeProducts[state.selectedProduct.id]
        : (state.bookWithName = state.bookWithName.filter(
            (i) => i.reference !== payload
          ))
    }
  },
  emptyCart(state) {
    state.cart = []
    state.bookReferences = []
    state.bookWithName = []
    state.booksWithReference = []
    state.openShoppingCartModal = false
  },
  addInventoryMode(state, payload) {
    state.isInventoryMode = payload
  },
  toggleShowCategories(state, payload) {
    state.settings.showCategories = payload
  },
  toggleShowCamera(state, payload) {
    state.settings.showCamera = payload
  },
  setOfflineMode(state, payload) {
    state.offlineMode = payload
  },
  resetOfflineProducts(state) {
    state.offlineProducts = {}
  },
  setOfflineProducts(state, { dictionary, array }) {
    state.offlineProducts = dictionary
    state.lunrCtx = lunr(function () {
      this.field("name")
      this.field("sku")

      array.forEach((doc) => {
        this.add({
          ...doc,
          name: doc.product_attributes.name,
          sku: doc.sku,
        })
      }, this)
    })
  },
  setCart(state, payload) {
    state.cart = payload
  },
  SET_RESTOCK_MODE(state, payload) {
    state.restockMode = payload
  },
  SET_SUPPLIER(state, payload) {
    state.currentRestockSupplier = payload
  },
  SET_CASHPAYMENT_MODE(state, payload) {
    state.cashPaymentMode = payload
  },
  SET_CASHWITHDRAWAL_MODE(state, payload) {
    state.cashWithdrawalMode = payload
  },
  SET_SUPPLIERPAYMENT_MODE(state, payload) {
    state.supplierPaymentMode = payload
  },
  SET_LOAN_PAYMENT(state, payload) {
    state.loanPaymentMode = payload
  },
  SET_SERVICE_PAYMENT(state, payload) {
    state.servicePaymentMode = payload
  },
  SET_AIRTIME_RECHARGE(state, payload) {
    state.airtimeRechargeMode = payload
  },
  SET_PRODUCT_INVENTORY_MODE(state, payload) {
    state.productInventoryMode = payload
  },
  SET_PROMO_VALIDATION_MODE(state, payload) {
    state.promoValidationMode = payload
  },
  SET_BANK_DEPOSIT_MODE(state, payload) {
    state.bankDepositMode = payload
  },
  SET_CODI_PAYMENT_MODE(state, payload) {
    state.codiPaymentMode = payload
  },
  switchStarredProducts(state, payload) {
    state.starredProducts = payload
  },
  setBookStoreModal(state, payload) {
    state.bookStoreModal = payload
  },
  setSelectedProduct(state, payload) {
    state.selectedProduct = payload
  },
  setBookReferences(state, payload) {
    state.bookReferences = payload
  },
  setBookToAddId(state, payload) {
    !state.bookWithName.storeProducts[payload]
      ? (state.bookWithName.storeProducts[payload] = 1)
      : (state.bookWithName.storeProducts[payload] += 1)
  },
  setBookWithName(state, payload) {
    const bookIndex = state.bookWithName.findIndex(
      (item) => item.reference === payload
    )

    if (bookIndex > -1) {
      state.bookWithName[bookIndex].storeProducts[state.selectedProduct.id] += 1
    } else {
      const newBook = {
        reference: payload,
        storeProducts: {
          [state.selectedProduct.id]: 1,
        },
      }
      state.bookWithName.push(newBook)
    }
  },
  setReferenceToBook(state, payload) {
    state.bookWithName.forEach((item) => {
      const reference = state.booksWithReference.find(
        (i) => i.reference === item.reference
      )

      if (reference) {
        const storeProductKeys = Object.keys(reference.storeProducts)
        const storeProductKeysNew = Object.keys(item.storeProducts)
        if (storeProductKeys.includes(storeProductKeysNew[0])) {
          reference.storeProducts[storeProductKeysNew[0]] += 1
        } else {
          reference.storeProducts[storeProductKeysNew[0]] = 1
        }
      } else {
        state.booksWithReference.push(item)
      }
    })

    state.bookWithName = []
  },
  setReferenceToBookWithProductId(state, payload) {
    const reference = state.booksWithReference.find(
      (i) =>
        i.reference === payload.reference &&
        Object.keys(i.storeProducts).includes(payload.id)
    )

    if (reference) {
      reference.storeProducts[payload.id] += 1
    }
  },
  decreaseReferenceFromBook(state, payload) {
    const referenceIndex = state.bookReferences.findIndex((i) => i === payload)

    if (referenceIndex > -1) {
      state.bookReferences.splice(referenceIndex, 1)
    }

    const bookName = state.bookWithName.find((i) => i.reference === payload)

    if (bookName && bookName.storeProducts[state.selectedProduct.id] > 1) {
      bookName.storeProducts[state.selectedProduct.id] -= 1
    }
  },
  decrementBookWithReference(state, payload) {
    const bookName = state.booksWithReference.find(
      (i) =>
        i.reference === payload.reference &&
        i.storeProducts[payload.productId] > 1
    )

    if (bookName && bookName.storeProducts[payload.productId] > 1) {
      bookName.storeProducts[payload.productId] -= 1
    }
  },
  deleteReferenceToBook(state) {
    state.booksWithReference = []
  },
  setNewCartItem(state, payload) {
    state.newCartItem = payload
  },
}

const actions = {
  checkPromotions(context, payload) {
    const product_totals = []
    const promotion_ids = []
    const product = payload.data[0]
    const unit_price = product.unit_price
    if (product.promotion_attributes.length > 0) {
      payload.data[0].promotion_attributes.forEach((promotion) => {
        if (
          promotion.is_canceled === false &&
          promotion.is_published === true &&
          Date.now() / 1000 > new Date(promotion.begins_at).getTime() / 1000 &&
          Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
        ) {
          const unitsToNeedsToBuyRatio = Math.floor(
            promotion.needs_to_buy / promotion.needs_to_buy
          )
          const product_total_temp =
            promotion.promo_type === "reward_points"
              ? unit_price * promotion.needs_to_buy
              : promotion.promo_type === "price_discount"
              ? unit_price *
                (1 - promotion.discount / 100) *
                promotion.needs_to_buy
              : promotion.promo_type === "free_product"
              ? unit_price *
                (promotion.needs_to_buy -
                  unitsToNeedsToBuyRatio * promotion.gets_you)
              : unit_price * promotion.needs_to_buy

          product_totals.push(product_total_temp / promotion.needs_to_buy) // New unit price based on promotion
          promotion_ids.push(promotion.id)
        }
      })
      const promotionItem = product.promotion_attributes.find(
        (i) =>
          i.id ===
          promotion_ids[product_totals.indexOf(Math.min(...product_totals))]
      )
      context.commit("pushPromotions", { data: promotionItem })
    }
  },

  getPromotionTotal(context, payload) {
    const product = context.state.cart.find(
      (i) => i.product_attributes.id === payload.data[0].product_attributes.id
    )
    const units = product.units
    const unit_price = product.unit_price
    const product_total = []
    const product_totals = []

    if (payload.data[0].promotion_attributes.length > 0) {
      payload.data[0].promotion_attributes.forEach((promotion) => {
        if (
          units >= promotion.needs_to_buy &&
          promotion.is_canceled === false &&
          promotion.is_published === true &&
          Date.now() / 1000 > new Date(promotion.begins_at).getTime() / 1000 &&
          Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
        ) {
          const unitsToNeedsToBuyRatio = Math.floor(
            units / promotion.needs_to_buy
          )
          const product_total_temp =
            promotion.promo_type === "reward_points"
              ? unit_price * units
              : promotion.promo_type === "price_discount"
              ? unit_price * (1 - promotion.discount / 100) * units
              : promotion.promo_type === "free_product"
              ? unit_price *
                (units - unitsToNeedsToBuyRatio * promotion.gets_you)
              : unit_price * units
          product_totals.push(product_total_temp)
        } else {
          product_totals.push(unit_price * units)
        }
      })
      product_total.push(Math.min(...product_totals))
    } else {
      product_total.push(unit_price * units)
    }
    context.commit("calculateProductTotal", {
      cartItem: product,
      product_total: product_total[0],
    })
  },

  addMultipleProductToCart(context, payload) {
    const storeProduct = payload.data[0]
    let cartItem = context.state.cart.find((i) => i.id === storeProduct.id)

    const units = Number(storeProduct.units)

    if (cartItem) {
      if (cartItem.references?.length === 0) return

      context.commit("incrementProductQuantity", {
        cartItem,
        units_to_add: units,
      })
    } else {
      const productWithCount = [
        {
          ...storeProduct,
          product_total: 0,
        },
      ]

      context.commit("pushProductToCart", { data: productWithCount })
    }

    cartItem = context.state.cart.find((i) => i.id === storeProduct.id)

    context.commit("calculateProductTotal", {
      cartItem,
      product_total: cartItem.unit_price * units,
    })
  },

  addProductToCart(context, payload) {
    const storeProduct = payload.data[0]

    const cartItem = context.state.cart.find((i) => i.id === storeProduct.id)

    if (!cartItem) {
      const units_for_promotion = storeProduct.applicablePromotion
        ? storeProduct.applicablePromotion.needs_to_buy
        : 1
      const ProductWithCount = [
        {
          ...storeProduct,
          units: units_for_promotion,
          references: [JSON.stringify(payload.reference)],
          product_total: 0,
        },
      ]

      context.commit("pushProductToCart", { data: ProductWithCount })
      context.commit("deletePromotions", {})

      const product = context.state.cart.find(
        (i) => i.product_attributes.id === storeProduct.product_attributes.id
      )

      const units = product.units
      const unit_price = product.unit_price
      const product_total = []
      const product_totals = []

      if (storeProduct.promotion_attributes.length > 0) {
        storeProduct.promotion_attributes.forEach((promotion) => {
          if (
            units >= promotion.needs_to_buy &&
            promotion.is_canceled === false &&
            promotion.is_published === true &&
            Date.now() / 1000 >
              new Date(promotion.begins_at).getTime() / 1000 &&
            Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
          ) {
            const unitsToNeedsToBuyRatio = Math.floor(
              units / promotion.needs_to_buy
            )
            const product_total_temp =
              promotion.promo_type === "reward_points"
                ? unit_price * units
                : promotion.promo_type === "price_discount"
                ? unit_price * (1 - promotion.discount / 100) * units
                : promotion.promo_type === "free_product"
                ? unit_price *
                  (units - unitsToNeedsToBuyRatio * promotion.gets_you)
                : unit_price * units
            product_totals.push(product_total_temp)
          } else {
            product_totals.push(unit_price * units)
          }
        })

        product_total.push(Math.min(...product_totals))
      } else {
        product_total.push(unit_price * units)
      }
      context.commit("calculateProductTotal", {
        cartItem: product,
        product_total: product_total[0],
      })
    } else {
      const needs_to_buy = storeProduct.applicablePromotion
        ? storeProduct.applicablePromotion.needs_to_buy
        : 1
      const units_to_complete_promotion =
        Math.ceil(cartItem.units / needs_to_buy) * needs_to_buy - cartItem.units
      const units_to_add = storeProduct.applicablePromotion
        ? needs_to_buy > cartItem.units
          ? needs_to_buy - cartItem.units
          : Math.max(
              units_to_complete_promotion == 0
                ? needs_to_buy
                : units_to_complete_promotion,
              1
            )
        : 1

      if (!cartItem.references.includes(JSON.stringify(payload.reference)))
        cartItem.references.push(JSON.stringify(payload.reference))

      context.commit("incrementProductQuantity", { cartItem, units_to_add })
      context.commit("deletePromotions", {})

      const product = context.state.cart.find((i) => i.id === storeProduct.id)

      const units = product.units
      const unit_price = product.unit_price
      const product_total = []
      const product_totals = []

      if (storeProduct.promotion_attributes.length > 0) {
        storeProduct.promotion_attributes.forEach((promotion) => {
          if (
            units >= promotion.needs_to_buy &&
            promotion.is_canceled === false &&
            promotion.is_published === true &&
            Date.now() / 1000 >
              new Date(promotion.begins_at).getTime() / 1000 &&
            Date.now() / 1000 < new Date(promotion.ends_at).getTime() / 1000
          ) {
            const unitsToNeedsToBuyRatio = Math.floor(
              units / promotion.needs_to_buy
            )
            const product_total_temp =
              promotion.promo_type === "reward_points"
                ? unit_price * units
                : promotion.promo_type === "price_discount"
                ? unit_price * (1 - promotion.discount / 100) * units
                : promotion.promo_type === "free_product"
                ? unit_price *
                  (units - unitsToNeedsToBuyRatio * promotion.gets_you)
                : unit_price * units
            product_totals.push(product_total_temp)
          } else {
            product_totals.push(unit_price * units)
          }
        })
        product_total.push(Math.min(...product_totals))
      } else {
        product_total.push(unit_price * units)
      }
      context.commit("calculateProductTotal", {
        cartItem: product,
        product_total: product_total[0],
      })
    }
  },

  emptyCart(context) {
    context.commit("emptyCart")
    promotions.state.promoCodePromotion = null
  },

  handleLunr(context, storeId) {
    let productsDictionary = {}
    const db = new Localbase(`store-${storeId}`)
    db.collection("products")
      .get()
      .then((products) => {
        productsDictionary = Object.assign(
          {},
          ...products.map((x) => ({ [x.id]: x }))
        )
        context.commit("setOfflineProducts", {
          dictionary: productsDictionary,
          array: products,
        })
      })
  },
  handleLunr2(context, products) {
    const productsDictionary = Object.assign(
      {},
      ...products.map((x) => ({ [x.id]: x }))
    )
    context.commit("setOfflineProducts", {
      dictionary: productsDictionary,
      array: products,
    })
  },
  shutdown() {
    axios.post("http://localhost:3000/shutdown")
  },
  reboot() {
    axios.post("http://localhost:3000/reboot")
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
