import axios from '@axios'

export default {
  namespaced: true,
  state: {
    cfdiUses: [],
    taxRegimes: [],
    catalogsSat: {
      cfdiTypes: [],
      paymentMethods: [],
      paymentForms: [],
      fiscalRegimes: [],
    }
  },
  getters: {
    cfdiUses(state) {
      return state.cfdiUses
    },
    taxRegimes(state) {
      return state.taxRegimes
    },
    catalogsSat(state) {
      return state.catalogsSat
    },
  },
  mutations:{
    setCfdiUses(state, payload) {
      state.cfdiUses = payload
    },
    setTaxRegimes(state, payload) {
      state.taxRegimes = payload
    },
    setCatalogsSat(state, payload) {
      state.catalogsSat = payload
    }
  },
  actions: {
    fetchCfdiUses(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/cat_sat_cfdi_uses')
          .then(response => {
            ctx.commit('setCfdiUses', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTaxRegimes(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/cat_sat_tax_regimes')
          .then(response => {
            ctx.commit('setTaxRegimes', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCatalogsSat(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/catalogs_sats')
          .then(response => {
            ctx.commit('setCatalogsSat', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}