import axios from '@axios'

export default {
  namespaced: true,
  state: {
    promotions: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
    promoCodePromotion: null,
  },
  getters: {
    promotions(state) {
      return state.promotions.data
    },
    pagination(state) {
      return state.promotions.meta.pagination
    },
    promoCodePromotion(state) {
      return state.promoCodePromotion
    },
  },
  mutations: {
    setPromotions(state, payload) {
      state.promotions = payload
    },
    setPromoCodePromotion(state, payload) {
      state.promoCodePromotion = payload
    },
    deletePromoCodePromotion(state){
      state.promoCodePromotion = {};
    },
  },
  actions: {
    fetchPromotions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/promotions', {
            params
          })
          .then(response => {
            ctx.commit('setPromotions', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    verifyPromoCode(ctx, {promoCode, storeId}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/promotions/find_by_promo_code?promo_code=${promoCode}&store_id=${storeId}`)
          .then(response => {
            ctx.commit('setPromoCodePromotion', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createPromotion(ctx, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/promotions', {
            promotion,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editPromotion(ctx, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/promotions/${promotion.id}`, {
            promotion,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    publishPromotion(ctx, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/promotions/${promotion.id}/publish`, {
            promotion,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelPromotion(ctx, promotion) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/promotions/${promotion.id}/cancel`, {
            promotion,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}