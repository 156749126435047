import Vue from "vue"
import { BootstrapVue, ToastPlugin, ModalPlugin } from "bootstrap-vue"
import VueCompositionAPI from "@vue/composition-api"
import VueQriously from "vue-qriously"
import VueCroppie from "vue-croppie"

import i18n from "@/libs/i18n"
import router from "./router"
import store from "./store"
import App from "./App.vue"

import GmapVue from "gmap-vue"

import "croppie/croppie.css"

// Global Components
import "./global-components"

// 3rd party plugins
import "@axios"
// import '@/libs/acl'
import "@/libs/sweet-alerts"
import "@/libs/portal-vue"
import "@/libs/toastification"
import "@/libs/tour"
import "@/libs/ripple"
import "@/@core/utils/validations/validations"
import "./registerServiceWorker"
import VueTour from "vue-tour"

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueQriously)
Vue.use(VueCroppie)
Vue.use(BootstrapVue)

// Composition API
Vue.use(VueCompositionAPI)

// Vue Tour
Vue.use(VueTour)

Vue.use(GmapVue, {
  load: {
    // [REQUIRED] This is the unique required value by Google Maps API
    key: process.env.VUE_APP_API_GOOGLE_MAPS_API,
    // [OPTIONAL] This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    libraries: "places",

    // [OPTIONAL] If you want to set the version, you can do so:
    v: "3.26",

    // This option was added on v3.0.0 but will be removed in the next major release.
    // If you already have an script tag that loads Google Maps API and you want to use it set you callback
    // here and our callback `GoogleMapsCallback` will execute your custom callback at the end; it must attached
    // to the `window` object, is the only requirement.
    customCallback: "MyCustomCallback",
  },
  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: false,
  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
  dynamicLoad: false,
})

// import core styles
require("@core/scss/core.scss")

// import assets styles
require("@/assets/scss/style.scss")

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css") // For form-wizard

// Vue Tour
require("vue-tour/dist/vue-tour.css")

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
