export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/registrar-cliente',
    name: 'auth-register-client',
    component: () => import('@/views/user/AddCustomerForm.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/registrar-cliente-de-establecimiento',
    name: 'auth-register-establishment-client',
    component: () => import('@/views/user/AddCustomerForm.vue'),
    meta: {
      requiresAuth: true,
      isEstablishmentCustomer: true,
    },
  },
  {
    path: '/registrar-proveedor-de-establecimiento/:id',
    name: 'auth-register-establishment-supplier',
    component: () => import('@/views/user/AddSupplierForm.vue'),
    meta: {
      requiresAuth: true,
      isEstablishmentSupplier: true,
    },
  },
  {
    path: '/registrar-establecimiento',
    name: 'auth-register-establishment',
    component: () => import('@/views/user/AddEstablishmentForm.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register_new_account',
    name: 'register-new-account',
    component: () => import('@/views/user/RegisterNewAccount.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset_password/:token',
    name: 'auth-reset-password-v1',
    component: () => import('@/views/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
    },
  },
]
