import axios from '@axios'

export default {
  namespaced: true,
  state: {
    invoices: {
      data: [],
      meta: {
        pagination: {

        },
      },
    },
    selectedOrders: [],
  },
  getters: {
    invoices(state) {
      return state.invoices.data
    },
    pagination(state) {
      return state.invoices.meta.pagination
    },
    invoiceCount: state => state.invoices.meta.pagination.total_objects,
    selectedOrders(state) {
      return state.selectedOrders
    }
  },
  mutations:{
    setInvoices(state, payload) {
      state.invoices = payload
    },
    addSelectedOrder(state, orderId) {
      if (!state.selectedOrders.includes(orderId)) {
        state.selectedOrders.push(orderId);
      }
    },
    removeSelectedOrder(state, orderId) {
      const index = state.selectedOrders.indexOf(orderId);
      if (index !== -1) {
        state.selectedOrders.splice(index, 1);
      }
    },
    clearSelectedOrders(state) {
      state.selectedOrders = [];
    }
  },
  actions: {
    fetchInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/invoices', {
            params: {
              ...params,
            },
          })
          .then(response => {
            ctx.commit('setInvoices', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    registerInvoice(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/invoices', {
            invoice: {  
              ...params,
            }
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    sendByEmail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/invoices/send_by_email', {
            invoice: {  
              ...params,
            }
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // downloadInvoice(ctx, id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/v1/invoices/${id}/download_pdf`)
    //       .then(response => {
    //         resolve(response.data)
    //       })
    //       .catch(error => {
    //         reject(error)
    //       })
    //   })
    // }
  }
}