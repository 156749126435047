import axios from '@axios'

export default {
  namespaced: true,
  state: {
    recommenders: {},
  },
  getters: {
    recommenders(state) {
      return state.recommenders
    },
    recommender(state) {
      return state.recommender
    },
  },
  mutations: {
    set_recommenders(state, payload) {
      state.recommenders = payload
    },
    set_recommender(state, payload) {
      state.recommender = payload
    },
  },
  actions: {
    fetchRecommenders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/recommenders', {
            params,
          })
          .then(response => {
            ctx.commit('set_recommenders', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchRecommender(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/recommenders/${id}`)
          .then(response => {
            resolve(response.data)
            ctx.commit('set_recommender', response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editRecommender(ctx, recommender) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/recommenders/${recommender.id}`, {
            recommender,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
