import axios from '@axios'

export default {
  namespaced: true,
  state: {
    categoriesReport: [],
    clientsReport: [],
  },
  getters: {
    categoriesReport(state) {
      return state.categoriesReport
    },
    clientsReport(state) {
      return state.clientsReport
    },
  },
  mutations: {
    setCategoriesReport(state, payload) {
      state.categoriesReport = payload
    },
    setClientsReport(state, payload) {
      state.clientsReport = payload
    },
  },
  actions: {
    fetchDashboard() {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/dashboard/')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCategoriesReport(context, { storeId, frequency }) {
      return new Promise((resolve, reject) => {
        let url = `/v1/categories_report?frequency=${frequency}`; 
        if (storeId) {
          url += `&store_id=${storeId}`; 
        }
        axios
          .get(url)
          .then(response => {
            context.commit('setCategoriesReport', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchClientsReport(context, { storeId, frequency }) {
      return new Promise((resolve, reject) => {
        let url = `/v1/clients_report?frequency=${frequency}`;
        if (storeId) {
          url += `&store_id=${storeId}`;
        }
        axios
          .get(url)
          .then(response => {
            context.commit('setClientsReport', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
}
