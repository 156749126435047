import axios from "axios"
import promotions from "@/store/promotions"
import {
  getBestPromotion,
  getPromoTypeAndValue,
  calculateCartItemTotals,
} from "@/utils/store-product-utils"

const state = () => ({
  cart: [],
  bookStoreModal: false,
  newCartItem: null,
  bookReferences: [],
  bookWithName: [],
  selectedProduct: null,
  booksWithReference: [],
})

const getters = {
  cart: (state) => state.cart,
  cartProductsCount: (state) => {
    let total = 0
    state.cart.forEach((product) => {
      if (product.product_attributes?.is_weighted) {
        total += 1
      } else {
        total += Number(product.units)
      }
    })
    return total
  },
  cartWithPromotions: (state) => {
    return state.cart.map((cartItem) => {
      const bestPromotion = getBestPromotion(cartItem, null)

      const {
        productTotal,
        discount,
        rewardPoints,
        itemIva,
        itemPrice,
        itemSubtotal,
      } = calculateCartItemTotals(cartItem, bestPromotion)

      return Object.assign({}, cartItem, {
        itemIva,
        itemPrice,
        itemSubtotal,
        itemDiscount: discount,
        itemTotal: productTotal,
        itemRewardPoints: rewardPoints,
        uniqueId: Math.random().toString(36).substring(7)
      })
    })
  },
  cartDetails: (state, getters) => {
    let tax = 0
    let total = 0
    let subtotal = 0
    let discount = 0
    let rewardPoints = 0

    getters.cartWithPromotions.forEach((cartItem) => {
      total += cartItem.itemTotal
      tax += cartItem.itemIva
      subtotal += cartItem.itemSubtotal
      discount += cartItem.itemDiscount
      rewardPoints += cartItem.itemRewardPoints
    })

    return {
      tax,
      total,
      subtotal,
      discount,
      rewardPoints,
    }
  },

  cartHasDiscount: (state, getters) => {
    return (
      getters.cartDetails.discount > 0 || getters.cartDetails.rewardPoints > 0
    )
  },

  cartDiscount: (state, getters) => {
    return getters.cartDetails.discount.toFixed(3)
  },

  cartSubtotal: (state, getters) => {
    return getters.cartDetails.subtotal.toFixed(3)
  },

  cartTax: (state, getters) => {
    return getters.cartDetails.tax.toFixed(3)
  },

  cartRewardPoints: (state, getters) => {
    return getters.cartDetails.rewardPoints.toFixed(3)
  },

  cartTotal: (state, getters) => {
    return getters.cartDetails.total.toFixed(3)
  },

  cartByReference: (state, getters, rootState, rootGetters) => {
    return getters.cartWithPromotions
  },
}

const mutations = {
  incrementCartItemQuantity: (state, { cartItem, units = 1 }) => {
    const index = state.cart.indexOf(cartItem)

    if (index !== -1) {
      state.cart[index].units += units
    }
  },

  pushCartItem: (state, payload) => {
    state.cart.push({
      ...payload,
      units: Number(payload.units) || 1,
    })
  },

  editCartItemQuantity: (state, { cartItem, units }) => {
    const index = state.cart.findIndex(
      (i) => i.id === cartItem.id && i.references === cartItem.references
    )

    const unitsNumber = Number(units)

    if (index !== -1) {
      if (unitsNumber === 0) {
        state.cart.splice(index, 1)
        return
      }

      state.cart[index].units = Number(units)
    }
  },
}

const actions = {
  addCartItem(context, payload) {
    const storeProduct = payload

    let cartItem = context.state.cart.find(
      (i) =>
        i.id === storeProduct.id &&
        JSON.stringify(i.references) === JSON.stringify(storeProduct.references)
    )

    const units = Number(storeProduct.units)

    if (cartItem) {
      context.commit("incrementCartItemQuantity", {
        cartItem,
        units,
      })
    } else {
      context.commit("pushCartItem", storeProduct)
    }
  },

  emptyCart(context) {
    context.state.cart = []
    context.rootState.pos.bookWithName = []
    context.rootState.pos.bookReferences = []
    context.rootState.pos.booksWithReference = []
    context.rootState.pos.openShoppingCartModal = false
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
