import axios from '@axios'

export default {
  namespaced: true,
  state: {
    servicePaymentsProducts: [],
    otherProducts: [],
  },
  getters: {
    servicePaymentsProducts(state) {
      return state.servicePaymentsProducts
    },
    otherProducts(state) {
      return state.otherProducts
    },
  },
  mutations: {
    setServicesPaymentsProducts(state, payload) {
      state.servicePaymentsProducts = payload
    },
    setOtherSerciesProducts(state, payload) {
      state.otherProducts = payload
    },
  },
  actions: {
    fetchEmidaProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/emida_products', {
            params,
          })
          .then(response => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(response.data)) {
              if (key === 'A') {
                ctx.commit('setOtherSerciesProducts', value)
              } else {
                ctx.commit('setServicesPaymentsProducts', value)
              }
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEmidaProduct(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/emida_products/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line camelcase
    purchaseTypeA(ctx, emida_product) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/emida_products/purchase_product_a', {
            emida_product,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line camelcase
    purchaseTypeB(ctx, emida_product) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/emida_products/purchase_product_b', {
            emida_product,
          })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addEmidaFunds(ctx, emida) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/emida_payment_funds', {
            account_type: emida.account_type,
            amount: emida.amount,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
