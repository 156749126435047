export default {
  namespaced: true,
  state: {
    enc: null,
  },
  getters: {
    enc(state) {
      return state.enc
    },
  },
  mutations: {
    SET_ENC(state, payload) {
      state.enc = payload
    },
  },
  actions: {
    startNfcService(ctx) {
      const targetUrl = 'ws://localhost:8613/ws'
      const ws = new WebSocket(targetUrl)

      ws.onopen = () => {
        ws.send('poll')
        // eslint-disable-next-line
        console.log('ws success')
      }

      ws.onmessage = evt => {
        // eslint-disable-next-line
        console.log(evt.data)
        const obj = JSON.parse(evt.data)
        const enc = /[^=]*$/.exec(obj.url)
        ctx.commit('SET_ENC', enc[0])
      }
    },
  },
}
