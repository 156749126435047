import axios from '@axios'

export default {
  namespaced: true,
  state: {
    csds: {
      data: [],
      meta: {
        pagination: {

        },
      },
    }
  },
  getters: {
    csds(state) {
      return state.csds.data
    },
    pagination(state) {
      return state.csds.meta.pagination
    },
    csdsCount: state => state.csds.meta.pagination.total_objects,
  },
  mutations:{
    setCsds(state, payload) {
      state.csds = payload
    }
  },
  actions: {
    fetchCsds(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/csd_tax_informations?by_establishment=${params.by_establishment}`)
          .then(response => {
            ctx.commit('setCsds', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    registerCsd(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/csd_tax_informations', params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    attachCsd(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/store_tax_informations', params)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deattachCsd(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/store_tax_informations/${params.csd_id}?store_id=${params.store_id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editCsd(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/csd_tax_informations/${params.id}`, {
            csd_tax_information: params.csd_tax_information
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteCsd(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/csd_tax_informations/${params.id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}