import axios from '@axios'
import Localbase from 'localbase'

export default {
  namespaced: true,
  state: {
    offlineOrders: [],
    orders: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
  },
  getters: {
    orders(state) {
      return state.orders.data
    },
    offlineOrders(state) {
      return state.offlineOrders
    },
    pagination(state) {
      return state.orders.meta.pagination
    },
  },
  mutations: {
    setOrders(state, payload) {
      state.orders = payload
    },
    setOfflineOrders(state, payload) {
      state.offlineOrders = payload
    },
    deleteOflineOrder(state, payload) {
      state.offlineOrders.splice(state.offlineOrders[payload], 1)
    },
    deleteOrders(state) { 
      state.orders.data = []
    }
  },
  actions: {
    generateOrdersConciliationFile(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/orders/generate_online_sales_conciliation_file', {
            params,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/orders', {
            params,
          })
          .then(response => {
            ctx.commit('setOrders', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchOfflineOrders(ctx, storeId) {
      const db = new Localbase(`store-${storeId}`)
      db.collection('orders')
        .get({ keys: true })
        .then(data => {
          ctx.commit('setOfflineOrders', data)
        })
    },
    fetchOrder(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/orders/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchOrderByPendingOrder(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/orders/${id}/by_pending_order`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchBraceletReport(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/orders/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addOrder(ctx, {
      order,
      orderType,
      isRefund = false,
    }) {
      return new Promise((resolve, reject) => {
        const db = new Localbase(`store-${order.store_id}`)
        db.collection('orders')
          .add({
            ...order,
            created_at: new Date(),
            order_type: orderType,
            is_refund: isRefund,
          })
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    dispatchBulkOrders(ctx, storeId) {
      return new Promise((resolve, reject) => {
        let orders = []
        let jsonOrders
        const db = new Localbase(`store-${storeId}`)
        db.collection('orders')
          .get()
          .then(dbOrders => {
            orders = dbOrders.map(order => ({
              ...order,
              store_id: storeId,
              store_clerk_id: order.store_clerk ? order.store_clerk.id : null,
            }))
            jsonOrders = JSON.stringify(orders)
          })
          .finally(() => {
            if (orders.length > 0) {
              axios
                .post('/v1/bulk_orders', {
                  bulk_order: {
                    orders: jsonOrders,
                  },
                })
                .then(() => {
                  ctx.dispatch('deleteOrdersFromIndexedDb', storeId)
                  resolve()
                })
            } else {
              // eslint-disable-next-line
              reject({
                status: 420,
                message: 'No hay ordenes',
              })
            }
          })
      })
    },
    dispatchOrder(ctx, {
      order,
      orderType,
      isRefund = false,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/orders/', {
            order: {
              ...order,
              order_type: orderType,
              is_refund: isRefund,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dispatchPresaleOrder(ctx, {
      order,
      orderType,
      isRefund = false,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/orders/presale_orders/', {
            order: {
              ...order,
              order_type: orderType,
              is_refund: isRefund,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dispatchOnlineOrder(ctx, { order }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/orders/online_orders/', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    dispatchLoanOrder(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/orders/loan_payment_orders', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editOrder(ctx, { id, order }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/orders/${id}`, {
            order,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    confirmDispatcher(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .put('/v1/orders/confirm_dispatcher', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deliveryConfirmation(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .put('/v1/orders/delivery_confirmation', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    confirmedByDispatcher(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .put('/v1/orders/confirmed_by_dispatcher', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    rejectedByDispatcher(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .put('/v1/orders/rejected_by_dispatcher', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelOnlineOrder(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .put('/v1/orders/cancel_online_order', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    sellerCancelsOnlineOrder(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .put('/v1/orders/seller_cancels_online_order', {
            order: {
              ...order,
            },
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteOrderFromIdexedDb(context, order) {
      const db = new Localbase(`store-${order.data.store_id}`)
      db.collection('orders')
        .doc(order.key)
        .delete()
      context.commit('deleteOflineOrder', order)
      const today = new Date()
      const firstDay = `${today.getFullYear()}-${today.getMonth() + 1}-01`
      const lastDay = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      context.dispatch('fetchOrders', {
        by_date: {
          start_date: firstDay,
          end_date: lastDay,
        },
        by_store: order.data.store_id,
      })
    },
    deleteOrdersFromIndexedDb(context, storeId) {
      const db = new Localbase(`store-${storeId}`)
      db.collection('orders').delete()
    },
    completeAndroidOrder() {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/android_app_callback', {
            android_app_room: '',
            bracelet_url: '',
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
