import axios from '@axios'

export default {
  namespaced: true,
  state: {
    walleat: {},
    accounting: {
      accountings: [
        {
          balance: 0,
        },
      ],
    },
    transactions: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
  },
  getters: {
    pagination(state) {
      return state.transactions.meta.pagination
    },
    transactions(state) {
      return state.transactions.data
    },
    walleat(state) {
      return state.walleat
    },
    accounting(state) {
      if (state.accounting.accountings[0].balance !== 0) {
        return state.accounting.accountings
      }
      const x = [
        {
          balance: 0,
        },
      ]
      return x
    },
  },
  mutations: {
    setTransactions(state, payload) {
      state.transactions = payload
    },
    SET_WALLEAT(state, payload) {
      state.walleat = payload
    },
    SET_CUSTOMER_BALANCE(state, payload) {
      state.accounting = payload
    },
  },
  actions: {
    fetchWalleats(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/bracelets', {
            params,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchWalleat(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bracelets/${id}`)
          .then(response => {
            resolve(response.data)
            ctx.commit('SET_WALLEAT', { ...response.data })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchWalleatGraph(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/bracelet_report', {
            params,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchWalleatByEnc(ctx, enc) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/find_by_bracelet_number', {
            params: {
              enc,
            },
          })
          .then(response => {
            // context.commit('setCurrentBracelet', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addWalleat(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/bracelets/', {
            bracelet: payload,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editWalleat(ctx, walleat) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/bracelets/${walleat.id}`, {
            bracelet: walleat,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addCredit(ctx, payment) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/payments', {
            payment,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    banItem(ctx, banneableItem) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/banneable_items', {
            banneable_item: banneableItem,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/balance_transactions', {
            params,
          })
          .then(response => {
            ctx.commit('setTransactions', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchCustomerBalance(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/customer_balance', {
            params,
          })
          .then(response => {
            ctx.commit('SET_CUSTOMER_BALANCE', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
