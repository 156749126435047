import axios from '@axios'

export default {
  namespaced: true,
  store_id: null,
  state: {
    selected_variants: [],
    variants: {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    },
    
  },
  getters: {
    variants(state) {
      return state.variants.data
    },
    selected_variants(state) {
      return state.selected_variants
    },
    pagination(state) {
      return state.variants.meta.pagination
    },
    
  },
  mutations: {
    set_selected_variants(state, payload) {
      state.selected_variants = payload
    },
    deleted_selected_variants(state) {
      state.selected_variants = []
    },
    set_store_id(state, payload) {
      state.store_id = payload
    },
    set_variants(state, payload) {
      state.variants = payload
    }
    
  },
  actions: {
    createVariant(ctx, variant) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/variants/', {
            variant,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchVariants(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/variants/', {
            params,
          })
          .then(response => {
            ctx.commit('set_variants', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    cancelVariant(ctx, variant) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/variants/${variant.id}`, {
            variant,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    activateVariant(ctx, variant) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/variants/${variant.id}`, {
            variant,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteOptionVariant(ctx, variant) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/variant_options/${variant.id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addOptionVariant(ctx, variant) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/variant_options`, {
            variant_option: variant,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
}
