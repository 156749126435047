import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    print(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://localhost:3000/ticket', {
            order,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    printEmidaInvoice(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://localhost:3000/ticket_phone_credits', {
            order,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    printEmidaErrorTransaction(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://localhost:3000/emida_error', {
            order,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
