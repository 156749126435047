<template>
  <b-dropdown
    v-if="isCustomer"
    id="help-button"
    no-caret
    variant="link"
    class="fixed-text help-button"
    toggle-class="text-decoration-none"
    dropup
  >
    <template #button-content>
      <i class="fab fa-whatsapp" style="font-size: 44px; color: #25D366;"></i>
    </template>
    <b-dropdown-item
      target="_blank"
      :href="`https://wa.me/${whatsAppNumber}?text=Hola,%20necesito%20ayuda`"
    >
      Contactar por WhatsApp
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters("landingPage", ["currentUser", "establishment"]),	

    isCustomer() {
      return !this.currentUser || this.currentUser.role_name === "customer"
    },
    whatsAppNumber() {
      return this.establishment.cel_number || "525529397947"
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.help-button {
  position: fixed;
  right: 20px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  bottom: 20px;
  
  @media screen and (max-width: 767px) {
    bottom: 80px;
    
  }
}
</style>
