import axios from '@axios'

export default {
  namespaced: true,
  state: {
    productPackages: {},
  },
  getters: {
    productPackages(state) {
      return state.productPackages
    },
  },
  mutations: {
    set_productPackages(state, payload) {
      state.productPackages = payload
    },
  },
  actions: {
    fetchProductPackages(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/product_packages')
          .then(response => {
            ctx.commit('set_productPackages', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line camelcase
    dispatchProductPackage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/save_from_package/?product_package_id=${params.product_package_id}&store_id=${params.store_id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
