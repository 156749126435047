export default [
  {
    path: '/selectStore',
    name: 'select-store',
    component: () => import('@/views/sales/SelectStore.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sales/:id',
    name: 'sales-view',
    component: () => import('@/views/sales/SalesReport.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/online-sales/:id',
    name: 'online-sales-view',
    component: () => import('@/views/sales/OnlineSalesTransactions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/categorias-de-productos/:id',
    name: 'product-categories-list',
    component: () => import('@/views/e-commerce/SelectProductCategoryType.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/seleccionar-tienda/abarrotes',
    name: 'select-online-groceries-store',
    component: () => import('@/views/stores/StoresListECommerce.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
      storeType: 'groceries',
    },
  },
  {
    path: '/:id/seleccionar-tienda/:store_type',
    name: 'select-online-store',
    component: () => import('@/views/stores/StoresListECommerce.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/seleccionar-tienda/libreria',
    name: 'select-online-book-store',
    component: () => import('@/views/stores/StoresListECommerce.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
      storeType: 'book_store',
    },
  },
  {
    path: '/facturas',
    name: 'select-online-invoice',
    component: () => import('@/views/invoices/InvoiceList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/facturas/generar-factura',
    name: 'generate-online-invoice',
    component: () => import('@/views/invoices/GenerateInvoice.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/abarrotes-en-linea/:store_id',
    name: 'e-commerce-groceries',
    component: () => import('@/views/establishments/WallEateCommerce.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
      contentRenderer: 'sidebar-left-detached',
      storeType: 'groceries',
    },
  },
  {
    path: '/libros-en-linea/:store_id',
    name: 'e-commerce-books',
    component: () => import('@/views/establishments/WallEateCommerce.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
      contentRenderer: 'sidebar-left-detached',
      storeType: 'book_store',
    },
  },
  {
    path: '/preventa-en-linea/:store_id',
    name: 'e-commerce-presale',
    component: () => import('@/views/establishments/WallEateCommercePresale.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
      contentRenderer: 'sidebar-left-detached',
    },
  },
  {
    path: '/e-commerce/:store_id',
    name: 'e-commerce',
    component: () => import('@/views/establishments/WallEateCommerce.vue'),
    meta: {
      requiresAuth: true,
      contentClass: 'ecommerce-application',
      contentRenderer: 'sidebar-left-detached',
    },
  }
]
