import axios from '@axios'

export default {
  namespaced: true,
  state: {
    currentSupplier: null,
    currentEstablishmentSupplier: null,
    suppliers: {
      data: [],
      meta: {
        pagination: {

        },
      },
    },
  },
  getters: {
    suppliers(state) {
      return state.suppliers.data
    },
    pagination(state) {
      return state.suppliers.meta.pagination
    },
    currentSupplier(state) {
      return state.currentSupplier
    },
    currentEstablishmentSupplier(state) {
      return state.currentEstablishmentSupplier
    },
    suppliersCount: state => state.suppliers.meta.pagination.total_objects,
  },
  mutations: {
    setCurrentSupplier(state, payload) {
      state.currentSupplier = payload
    },
    setCurrentEstablishmentSupplier(state, payload) {
      state.currentEstablishmentSupplier = payload
    },
    setSuppliers(state, payload) {
      state.suppliers = payload
    },
  },
  actions: {
    // eslint-disable-next-line camelcase
    addSupplierPayment(ctx, supplier_payment) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/supplier_payment', {
            supplier_payment,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSuppliers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/supplier', {
            params: {
              ...params,
            },
          })
          .then(response => {
            ctx.commit('setSuppliers', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSupplier(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/supplier/${id}`)
          .then(response => {
            resolve(response.data)
            ctx.commit('setCurrentSupplier', response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addSupplier(ctx, supplier) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/supplier', {
            supplier,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editSupplier(ctx, { id, supplier }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/supplier/${id}`, {
            supplier,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // Establishment Suppliers
    fetchEstablishmentSuppliers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/establishment_supplier', {
            params: {
              ...params,
            },
          })
          .then(response => {
            ctx.commit('setSuppliers', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchEstablishmentSupplier(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/establishment_supplier/${id}`)
          .then(response => {
            resolve(response.data)
            ctx.commit('setCurrentEstablishmentSupplier', response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line camelcase
    addEstablishmentSupplier(ctx, establishment_supplier) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/establishment_supplier', {
            establishment_supplier,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line camelcase
    editEstablishmentSupplier(ctx, establishment_supplier) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/establishment_supplier/${establishment_supplier.supplier_id}`, {
            establishment_supplier,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // Store Suppliers
    // eslint-disable-next-line camelcase
    addStoreSupplier(ctx, store_supplier) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/store_supplier', {
            store_supplier,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchStoreSuppliers(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/store_supplier', {
            params: {
              ...params,
            },
          })
          .then(response => {
            ctx.commit('setSuppliers', response.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchStoreSupplier(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/store_supplier/${id}`)
          .then(response => {
            resolve(response.data)
            ctx.commit('setCurrentSupplier', response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editStoreSupplier(ctx, { store_supplier }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/store_supplier/${store_supplier.id}`, {
            store_supplier,
          })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
