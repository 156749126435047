export function updateFavicon(iconURL) {
  let link = document.querySelector("link[rel~='icon']")

  if (!link) {
    link = document.createElement("link")
    link.rel = "icon"
    document.getElementsByTagName("head")[0].appendChild(link)
  }

  link.href = iconURL
}

export function updateTitle(newTitle) {
  document.title = newTitle
}

async function fetchManifest(url) {
  const response = await fetch(url, { cache: "no-cache" })
  return response.json()
}

const defaultManifestUrl = "/manifest.json"
const defaultFavicon = "/favicon.ico"
const defaultTitle = "Compitienda"

export async function handleManifest(to) {
  let manifest
  let dynamicManifestUrl

  if (to.name === "establishment-landing-page" && to.params.slug) {
    dynamicManifestUrl =
      process.env.VUE_APP_API_URL + "/v1/manifests/" + to.params.slug

    try {
      manifest = await fetchManifest(dynamicManifestUrl)
    } catch (error) {
      console.error("Error fetching manifest:", error)
      return
    }
  }

  if (manifest?.icons && manifest?.icons?.length > 0) {
    updateFavicon(manifest.icons[0].src)
  } else {
    updateFavicon(defaultFavicon)
  }

  if (manifest?.name) {
    updateTitle(manifest.name)
  } else {
    updateTitle(defaultTitle)
  }

  let manifestLink = document.querySelector("link[rel='manifest']")
  if (!manifestLink) {
    manifestLink = document.createElement("link")
    manifestLink.setAttribute("rel", "manifest")
    document.head.appendChild(manifestLink)
  }
  if (manifest && dynamicManifestUrl) {
    manifestLink.setAttribute(
      "href",
      `${dynamicManifestUrl}?v=${new Date().getTime()}`
    )
  } else {
    manifestLink.setAttribute(
      "href",
      `${defaultManifestUrl}?v=${new Date().getTime()}`
    )
  }
}
